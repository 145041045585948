import React from "react";
import path from "path";
import { graphql, navigate } from "gatsby";
import Layout from "../components/Layout";
import Card from "../components/Card";
import { Box, Grid } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { isBrowser } from "react-device-detect";
import SEO from "../components/SEO";

const Posts = ({ posts, pathPrefix }) => {
  return (
    <Grid container spacing={3}>
      {posts.map(
        ({
          node: {
            excerpt,
            fileAbsolutePath,
            frontmatter: { id, title, featuredImage }
          }
        }) => {
          const postDate = path
            .basename(fileAbsolutePath)
            .split("-")
            .splice(0, 3)
            .join("-");
          return (
            <Grid item xs={12} sm={4} key={id}>
              <Card
                featuredImage={featuredImage}
                title={title}
                url={`/${pathPrefix}/${id}`}
                postDate={postDate}
                excerpt={excerpt}
              />
            </Grid>
          );
        }
      )}
    </Grid>
  );
};

export default function ResultsAllTemplate({
  data: {
    site: {
      siteMetadata: {
        templates: {
          posts: { pathPrefix }
        }
      }
    },
    allMdx: { edges: posts }
  },
  pageContext: { totalPages, currentPage }
}) {
  const setPage = newPage => {
    navigate(`/${pathPrefix}/page/${newPage}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const Pager = (
    <Box paddingTop={2} paddingBottom={4}>
      <SEO title="All posts" />
      <Pagination
        style={{
          padding: 8,
          color: "white",
          backgroundColor: "white"
        }}
        variant="outlined"
        size="large"
        shape="rounded"
        // color=""
        // color="secondary"
        page={currentPage}
        count={totalPages}
        onChange={(event, page) => navigate(`/${pathPrefix}/page/${page}`)}
      />{" "}
    </Box>
  );

  return (
    <Layout>
      <Box flexGrow={1} width="100%" maxWidth={960} marginX="auto">
        <Box padding={1}>
          {isBrowser ? undefined : Pager}
          <Posts posts={posts} pathPrefix={pathPrefix} />
          {isBrowser ? Pager : undefined}
        </Box>
      </Box>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        templates {
          posts {
            pathPrefix
          }
        }
      }
    }
    allMdx(
      filter: { fileAbsolutePath: { regex: "/content/posts/" } }
      sort: { order: DESC, fields: [fileAbsolutePath] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          fileAbsolutePath
          frontmatter {
            id
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
